import { FC } from "react";
import { TeamResponse } from "@/web-client/api";
import Link from "next/link";
import Avatar, { AvatarSize } from "@/components/Avatar";

interface Props {
  team: TeamResponse;
  size?: AvatarSize;
}

const TeamIcon: FC<Props> = ({
  team,

  size = AvatarSize.Normal,
}): JSX.Element => {
  return (
    <div className="flex items-center space-x-8 overflow-hidden">
      <Link href={`/teams/${team.id}/projects`} className="flex-shrink-0">
        <Avatar
          src={team.logo ? team.logo.urls.small : "/imgs/default_team_icon.png"}
          alt={team.name}
          size={size}
        />
      </Link>

      <p className="flex-1 truncate">
        <Link href={`/teams/${team.id}/projects`} className="text-sm ">
          {team.name}
        </Link>
      </p>
    </div>
  );
};

export default TeamIcon;
