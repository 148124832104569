import { FC, PropsWithChildren } from "react";
import { LikeKind, ListItemKind, ProjectResponse } from "@/web-client/api";
import { ImageSize } from "@/consts/ImageSize";
import Link from "next/link";
import AddListBtnWrapper from "@/features/list/components/AddListBtnWrapper";
import LinkAsModal, { ModalEntityType } from "@/components/LinkAsModal";
import TeamIcon from "@/features/team/components/TeamIcon";
import LikeIconBtn from "@/features/like/components/LikeIconBtn";
import useLike, { LikeMutateHandler } from "@/features/like/hooks/useLike";
import FadingBackgroundImage from "@/components/FadingBackgroundImage";
import IconAdd from "../../../assets/imgs/svg/icon-add.svg";
import useIsMyTeam from "@/features/team/hooks/useIsMyTeam";
import ProjectItemMenuBtn from "@/features/project/components/ProjectItemMenuBtn";

type Props = {
  project: ProjectResponse;
  hasTeam?: boolean;
  mutate?: LikeMutateHandler<ProjectResponse>;
  showMenuBtn?: boolean;
  removeMutation?: () => void;
};

const ProjectItem: FC<Props> = ({
  project,
  mutate,
  removeMutation,
  hasTeam = true,
  showMenuBtn = false,
}) => {
  const { likeHandler } = useLike<ProjectResponse>(
    project,
    LikeKind.PROJECT,
    mutate,
  );
  const isMyTeam = useIsMyTeam(project.team.id);

  return (
    <article className="bg-white relative z-0 group duration-300 transition-all overflow-hidden rounded-xs">
      {hasTeam && (
        <header
          className="relative p-12 flex justify-between items-center"
          style={{ zIndex: 2 }}
        >
          <TeamIcon team={project.team} />
          <div className="flex-shrink-0 flex items-center gap-4">
            {project.is_published && (
              <AddListBtnWrapper
                kind={ListItemKind.PROJECT}
                selectedItem={project}
              >
                <span className="block rounded-full p-4 hover:bg-gray-50">
                  <IconAdd width={20} height={20} />
                </span>
              </AddListBtnWrapper>
            )}

            {project.is_published && (
              <LikeIconBtn hasLiked={project.has_liked} onClick={likeHandler} />
            )}
          </div>
        </header>
      )}
      <div className="flex flex-col">
        <div className="relative aspect-video overflow-hidden">
          <FadingBackgroundImage
            image={project.main_image}
            imageSize={ImageSize.Small}
            aspectRatio="16/9"
          />

          {!project.is_published && (
            <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
              <p className="font-bold text-white text-sm">
                非公開のプロジェクト
              </p>
            </div>
          )}

          <LinkForProject project={project}>
            <a className="absolute inset-0 block" />
          </LinkForProject>

          {isMyTeam && showMenuBtn && (
            <div className="absolute top-[8px] right-[8px]">
              <ProjectItemMenuBtn
                projectId={project.id}
                onDeleted={removeMutation}
              />
            </div>
          )}
        </div>

        <div className="p-16 pt-12 grid gap-y-16">
          <div className="relative z-10 leading-tight overflow-hidden">
            <Link
              href={`/architectures/projects/search?building_type_id=${project.building_type.id}`}
              prefetch={false}
              className="realtive inline-block text-xs text-secondary truncate hover:underline"
            >
              {project.building_type.name}
            </Link>

            <h1 className="text-sm font-bold truncate row-start-2">
              <LinkForProject project={project}>
                <a>{project.title}</a>
              </LinkForProject>
            </h1>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ProjectItem;

const LinkForProject: FC<PropsWithChildren<{ project: ProjectResponse }>> = ({
  children,
  project,
}) => {
  if (!project.is_published) {
    return (
      <Link href={`/projects/${project.id}`} prefetch={false} legacyBehavior>
        {children}
      </Link>
    );
  }

  return (
    <LinkAsModal
      entityId={project.id}
      entityType={ModalEntityType.Project}
      as={`/projects/${project.id}`}
    >
      {children}
    </LinkAsModal>
  );
};
