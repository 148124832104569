import { FC } from "react";
import IconButton from "@/components/IconButton";
import useDisclosure from "@/hooks/useDisclosure";
import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import ProjectItemMenu from "@/features/project/components/ProjectItemMenu";
import IconMore from "../../../../assets/imgs/svg/icon_more.svg";

interface Props {
  projectId: number;
  onDeleted: () => void;
}

const ProjectItemMenuBtn: FC<Props> = ({ projectId, onDeleted }) => {
  const { isOpen, openHandler, toggle } = useDisclosure();
  const { refs, floatingStyles, context } = useFloating<HTMLDivElement>({
    open: isOpen,
    onOpenChange: toggle,
    placement: "bottom-end",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context, { outsidePress: true }),
    useClick(context, { toggle: true }),
  ]);

  return (
    <>
      <IconButton
        size="small"
        variant="secondary"
        ref={refs.setReference}
        onClick={openHandler}
        {...getReferenceProps()}
      >
        <IconMore />
      </IconButton>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <ProjectItemMenu projectId={projectId} onDeleted={onDeleted} />
        </div>
      )}
    </>
  );
};
export default ProjectItemMenuBtn;
