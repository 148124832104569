import { FC } from "react";
import { theme } from "tailwind.config";
import IconLike from "../../../assets/imgs/svg/icon-like.svg";
import IconLikeFill from "../../../assets/imgs/svg/icon-like-fill.svg";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { RedirectReason } from "@/domain/values/RedirectReason";

type Props = {
  hasLiked: boolean;
  onClick: VoidFunction;
  size?: number;
};

const LikeIconBtn: FC<Props> = ({
  onClick,
  hasLiked,
  size = 20,
}): JSX.Element => {
  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    reason: RedirectReason.Like,
    action: onClick,
  });

  return (
    <>
      <button onClick={executeAction}>
        {!hasLiked && (
          <IconLike width={size} height={size} fill={theme.colors.gray[600]} />
        )}
        {hasLiked && (
          <IconLikeFill width={size} height={size} fill={theme.colors.like} />
        )}
      </button>
      {SignInModalComponent}
    </>
  );
};

export default LikeIconBtn;
