import { FC, useCallback } from "react";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import IconWarning from "../../../../assets/imgs/svg/icon-warning.svg";
import { deleteProject } from "@/features/project/api/deleteProject";
import useProgress from "@/hooks/useProgress";

interface Props {
  isOpen: boolean;
  close: VoidFunction;
  projectId: number;
  onDeleted: VoidFunction;
}

const DialogDeleteProject: FC<Props> = ({
  isOpen,
  close,
  projectId,
  onDeleted,
}) => {
  const progress = useProgress();

  const deleteBtnHandler = useCallback(() => {
    progress(
      async () => {
        await deleteProject({ id: projectId });

        close();

        onDeleted();
      },
      { errorMessage: "プロジェクトの削除に失敗しました" },
    );
  }, []);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      icon={<IconWarning width={56} height={56} />}
      title={"プロジェクトの削除"}
      btns={
        <div className="grid grid-cols-2 gap-12">
          <Button size="small" color="neutral" onClick={close}>
            キャンセル
          </Button>
          <Button size="small" color="danger" onClick={deleteBtnHandler}>
            削除
          </Button>
        </div>
      }
      hiddenCloseBtn
    >
      <p>
        プロジェクトを削除します。削除をおこなうとプロジェクトに紐づく写真などがすべて削除され、復旧することはできません。よろしいですか？
      </p>
    </Dialog>
  );
};
export default DialogDeleteProject;
