import clsx from "clsx";
import { FC, forwardRef, MouseEventHandler, ReactNode } from "react";

interface MenuListProps {
  items: (MenuListItemProps & { key: string })[][];
}

const MenuList: FC<MenuListProps> = ({ items: rootItems }) => {
  return (
    <ul
      className={clsx(
        "grid rounded-xs overflow-hidden bg-white shadow-xs min-w-[160px]",
        "[&>*+*]:border-t [&>*+*]:border-primary",
      )}
    >
      {rootItems.map((items, index) => (
        <li key={index}>
          <ul>
            {items.map(({ label, onClick, key }) => (
              <li key={key} className="grid justify-self-stretch">
                <MenuListItem onClick={onClick} label={label} />
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};
export default MenuList;

interface MenuListItemProps {
  onClick: MouseEventHandler;
  label: ReactNode;
}

const MenuListItem: FC<MenuListItemProps> = ({ onClick, label }) => {
  return (
    <button
      className={clsx(
        "p-12 leading-none text-sm text-left transition-all",
        "hover:bg-gray-200",
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
