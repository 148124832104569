import { RootState } from "@/stores/rootReducer";
import { TeamMemberEntity, TeamMemberKind } from "@/web-client/api";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useUserStatus from "@/features/user/hooks/useUserStatus";

interface Options {
  skipCheckDesigner?: boolean;
  isOwner?: boolean;
}

const useIsMyTeam = (
  teamId: number | undefined,
  { isOwner, skipCheckDesigner }: Options = {},
): boolean => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { isDesigner } = useUserStatus();

  const targetTeamMember = useMemo<TeamMemberEntity | undefined>(() => {
    if (!user) return;

    return user.team_members.find(
      (team_member) => team_member.team_id == teamId,
    );
  }, [user, teamId]);

  const isMyTeam = useMemo<boolean>(() => {
    if (!targetTeamMember) return false;

    // TODO: チームに紐づいているアカウントがすべてPROアカウントになった場合は、
    // このロジックは必要なくなるので、そのときに削除する
    if (!skipCheckDesigner && !isDesigner) return false;

    if (isOwner) {
      return targetTeamMember.kind == TeamMemberKind.OWNER;
    }

    return true;
  }, [targetTeamMember, isOwner, skipCheckDesigner, isDesigner]);

  return isMyTeam;
};

export default useIsMyTeam;
